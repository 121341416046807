<template>
  <div>
    <h1>Welcome to PTT Laos</h1>
    <hr/>
    <div class="page-header">
      <!-- <div class="page-title"></div> -->
      <div class="upload">
        <button class="button primary" @click="$refs.excelFile.click()">
          Upload Excel
        </button>
        <input type="file" ref="excelFile" @change="UploadExcel" />
      </div>
      <button class="button grey mg-left" @click="clean">Clean</button>
      <div class="header-end">
        <button
          class="button primary"
          style="margin-right: 10px"
          @click="isStop = true"
          title="One Click Only"
        >
          Stop
        </button>
        <button class="button danger" @click="sendAll()" title="One Click Only">
          Send All
        </button>
      </div>
    </div>

    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Email</th>
          <th>Mobile</th>
          <th>Basic Salary</th>
          <th>Net Salary</th>
          <th>Date</th>
          <th>Status</th>
          <!-- <th class="tb-xs">Option</th> -->
        </tr>
      </thead>
      <tbody>
        <tr v-for="(i, idx) in b" :key="idx">
          <td>{{ i.ID }}</td>
          <td>{{ i.name }}</td>
          <td>{{ i.email }}</td>
          <td>{{ i.mobile }}</td>
          <td>
            {{
              new Intl.NumberFormat("LAK", {
                style: "currency",
                currency: "LAK",
              }).format(i.basicSalary)
            }}
          </td>
          <td>
            {{
              new Intl.NumberFormat("LAK", {
                style: "currency",
                currency: "LAK",
              }).format(i.netSalary)
            }}
          </td>
          <td>{{ i.date }}</td>
          <td>
            <button
              class="button danger"
              @click="send(i)"
              title="If status true please do not click again"
              :class="i.sent ? 'primary' : 'danger'"
            >
              {{ i.sent }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <LoadingScreen v-if="isloading" />
  </div>
</template>

<script>
import moment from "moment";
import getJsDateFromExcel from "excel-date-to-js";
import XLSX from "xlsx";
import LoadingScreen from "./LoadingScreen";
export default {
  components: {
    LoadingScreen,
  },
  data: () => ({
    b: [],
    isloading: false,
    isStop: false,
  }),
  methods: {
    async UploadExcel($file) {
      const file = $file.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, {
          type: "binary",
        });
        workbook.SheetNames.map(async (sheetName) => {
          const xlRowObject = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[sheetName]
          );
          const jsonObject = JSON.stringify(xlRowObject);
          const parseJson = JSON.parse(jsonObject);
          const mapper = parseJson.map((obj) => {
            const dates = new Date(
              Math.round((obj["date"] - 25569) * 86400 * 1000)
            );
            const k = {
              ID: obj["ID"],
              name: obj["Name"],
              email: obj["Email"],
              mobile: obj["mobile"],
              insurance:obj["insurance"],
              option: obj["Option"],
              position: obj["Position"],
              costCenter: obj["cost center"],
              workLocation: obj["working location"],
              basicSalary: obj["ເງິນເດືອນພື້ນຖານ"],
              earnings: [],
              deductions: [],
              netSalary: obj["NetSalary"],
              date: moment(dates).locale("lo").format("YYYY-MM"),
              sent: false,
            };
            for (const key in obj) {
              //  console.log(`${key}: ${obj[key]}`)
              const first = key.split(" - ")[0];
              const last = key.split(" - ")[1];

              switch (first) {
                case "E": {
                  if (obj[key] === 0) break;
                  k.earnings.push({
                    name: last,
                    amount: obj[key],
                  });
                  break;
                }
                case "D": {
                  // if (obj[key] === 0) break;
                  k.deductions.push({
                    name: last,
                    amount: obj[key],
                  });
                  break;
                }
              }
            }
            return k;
          });
           //console.log(mapper);
          this.excel = mapper;
          localStorage.setItem("session2", JSON.stringify(this.excel));
          this.b = JSON.parse(localStorage.getItem("session2")) || [];
        });
      };
      reader.onerror = (ex) => {
        alert(ex);
      };
      reader.readAsBinaryString(file);
      // this.$refs.FileInput.value = null;
    },
    // send mail
    async sendAll() {
      const filter = this.b.filter((i) => !i.sent);
      for (let i = 0; i < filter.length; i++) {
        if (this.isStop) return;
        const obj = filter[i];
        await new Promise((resolve) =>
          setTimeout(async () => {
            await this.send(obj);
            resolve("succeed");
          }, 1000)
        );
      }
    },
    async send(i) {
      await this.$Axios.post(this.URL + `add-data`, {
        data: i,
      });
      const idx = this.b.findIndex((o) => o.ID === i.ID);
      this.b[idx].sent = true;
      localStorage.setItem("session2", JSON.stringify(this.b));
    },
    clean() {
      this.b = [];
      localStorage.removeItem("session2");
    },
  },
  created() {
    this.b = JSON.parse(localStorage.getItem("session2")) || [];
  },
};
</script>
<style lang="scss" scoped>
.page-header {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  .upload {
    input {
      display: none;
    }
  }
  .page-title {
    font-size: 30px;
    font-weight: 700;
    margin-right: 10px;
  }
  .header-end {
    margin-left: auto;
  }
}

.table {
  width: 100%;
  border-collapse: collapse;
  font-family: Saysettha ot;
  th {
    padding: 5px 0;
    &.tb-xs {
      width: 10%;
    }
  }
  td {
    padding: 5px 0;
    border-top: 1px solid $border-color;
    font-size: 16px;
  }
}

button.button {
  padding-bottom: calc(0.5em - 1px);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: calc(0.5em - 1px);
  border: none;
  height: 2.5em;
  font-size: 14px;
  box-shadow: none;
  cursor: pointer;
  &.mg-left {
    margin-left: 10px;
  }
  &.danger {
    background-color: red;
    color: #fff;
  }
  &.grey {
    background-color: $grey-color;
    color: $font-color;
  }
  &.primary {
    background-color: $primary-color;
    color: #fff;
  }
  &:focus {
    outline: none;
  }
}
</style>
